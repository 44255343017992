
.spancontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cta {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 2px 0px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: italic;
    color: coral;
    background: #121110;
    transition: 1s;
    box-shadow: 10px 10px 0 black;
    transform: skewX(-15deg);

}

.mobileBurgerMenu{
margin-top: 2rem;
margin-bottom: 2rem;}

.cta:focus {
    outline: none;
}

.cta:hover {
    transition: 0.5s;
    box-shadow: 5px 5px 0 coral;
}

.cta .ctaspan:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px;
}

.cta:hover  .ctaspan:nth-child(2) {
    transition: 0.5s;
    margin-right: 45px;
}

.ctaspan {
    transform: skewX(15deg);
}

.ctaspan:nth-child(2) {
    width: 30px;
    margin-left: 30px;
    position: relative;
    top: 12%;
}

/**************SVG****************/

path.one {
    transition: 0.4s;
    transform: translateX(-60%);
}

path.two {
    transition: 0.5s;
    transform: translateX(-30%);
}

.cta:hover path.three {
    animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
    0% {
        fill: white;
    }
    50% {
        fill: coral;
    }
    100% {
        fill: white;
    }
}
