@use '../../../../styles/mixins';
@import '../../../../styles/variables';


.ctaButtonWrapper{
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;
  @include mixins.mobile{
    flex-direction: column;
  }

}

.copyright{
  margin-right: auto;
  margin-left: 1rem;
  font-size: $mino_small_font_size;
  @include mixins.mobile{

    margin-left: auto;
  }
}

.legalWrapper{
  font-size: 1rem;
  @include mixins.mobile{
    font-size: 0.5rem;
  }
  display: flex;
  flex-wrap: wrap;
  line-height: 0.1;
  color: navajowhite;
  justify-content: center;
  align-items: center;
}

.companyRefWrapper{
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.companyRefItemContainer{
  text-align: left;
margin-left: 1rem;
  margin-right: 1rem;
}
.companyLogoWebsite{
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.companyLink{
  color: navajowhite;
}

.ctaButton{
background-color: #cda68a;
  border-radius: 10px;
  width: 100px;
  height: 50px;
}

.wrapperContainer{
  font-family: DMSans,sans-serif;

  bottom: 0;
  position: relative;
  padding-top: 2rem;
  z-index: 99;
  margin-top: auto;
  width: 100%;
  text-align: center;
  background: $mino_background-color;
  -webkit-mask-image: -webkit-gradient(linear, left 30%, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

