@import '../../../../styles/variables';

.cardContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.cardWrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
gap:2rem;
  .cardImage{
    text-align: center;
    border-radius: 40px;
    width: 300px;
  }



  .cardName{
    margin-top: 1rem;
margin-bottom: 0;
    font-size: 1.5rem;
    text-align: center;
    color: navajowhite;
  }

  .cardProfession{
    padding: 0;
    margin: 0;
    text-align: center;

    font-size: 1.2rem;
    color: navajowhite;
  }

  .horizontalLine{
    display: block;
    height: 1px;
    margin: 0.5rem;
    border: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));

  }

  .socialIconWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .icon{
width: 32px;
  }
}