@use 'src/styles/mixins';
@import 'src/styles/variables';


.centeredMainText{
  font-family: DMSans,sans-serif;
  font-weight: bold;
  text-align: justify;
  width: 95%;
  font-size: $mino_main_font_size;
  color: #FFF8BE;
  margin-left: auto;
  margin-right: auto;
}

.leftAlignedMainText{
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $mino_main_font_size;
  color: navajowhite;
}

.headlineOsakeTilt{
  transform: rotate(-5deg);
  padding-top: 5rem;
  padding-bottom: 2rem;
  margin: 0;
  font-family: Osake, serif;
  text-align: center;
  font-size: $mino_headline_font_size;
  font-weight: $mino_headline_font_weight;
  color: #fdfdfe;
  text-shadow: 0px 0px 5px orange, 0px 0px 10px orange, 0px 0px 10px orange,
  0px 0px 20px orange;

  @include mixins.mobile{
    font-size: $mino_headline_font_size_mobile;
  }
}
.headlineOsake{
  margin-top: 5rem;
  font-family: Osake, serif;
  text-align: center;
  font-size: $mino_headline_font_size;
  font-weight: $mino_headline_font_weight;
  color: #fdfdfe;
  text-shadow: 0px 0px 5px orange, 0px 0px 10px orange, 0px 0px 10px orange,
  0px 0px 20px orange;
}

.headlineOsakeNoPadding{
  margin: 0;
  padding: 0;
  font-family: Osake, serif;
  text-align: center;
  font-size: $mino_main_font_size;
  font-weight: $mino_headline_font_weight;
  color: #fdfdfe;
  text-shadow: 0px 0px 5px orange, 0px 0px 10px orange, 0px 0px 10px orange,
  0px 0px 20px orange;
}


.headlineLeft{
  transform: rotate(-5deg);
  padding-top: 5rem;
  padding-bottom: 2rem;
  margin: 0;
  font-family: Osake, serif;
  color: navajowhite;
  font-size: 5vh;
  text-align: center;
}

.headlineLeftNoTilt{
  margin: 0;
  font-family: Osake, serif;
  color: navajowhite;
  font-size: 3vh;
  text-align: left;
}
.leftText{
  font-family: Bankai, serif;
  color: navajowhite;
  font-size: 2rem;
  text-align: left;
}

.rightTextOsake{
  font-family: Osake, serif;
  color: navajowhite;
  font-size: 2vh;
  text-align: right;
}

.centeredText{
  text-align: center;
}

.mainContent{
  display: block;
  z-index: 99;
  position: relative;
  background: #121110 url("../assets/backgrounds/RAVINETRUE-min.png.webp") no-repeat center;
  background-size: cover;
}

.wrapperContainer{
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  z-index: 99;
  position: relative;
  background: transparent;
  @include mixins.mobile {
    width: 95%;
  }

}

.hLine{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding-bottom: 0.5rem;
}

.fixed{
  overflow: hidden;
}

.cardBorderGlow{
  border: 4px solid #dadada;
  box-shadow:
          0 0 6px 3px #fff,  /* inner */
          0 0 10px 6px #FFAE00, /* middle  */
          0 0 14px 9px #FF5500; /* outer */
}

.fullHeight{
  height: 100vh;
  display: flex;
  flex-direction: column;

}
