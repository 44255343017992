@import "./styles/variables";

html, body {
  overflow-x: hidden;
  background-color: #121110;}
body {
  position: relative
}

@font-face {
  font-family: 'Osake';
  src: local('Osake'), url(assets/fonts/Osake/Osake.woff2) format('woff'), url(assets/fonts/Osake/Osake.woff) format('woff') ;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(assets/fonts/Poppins/Poppins.ttf) format('truetype');
}
@font-face {
  font-family: 'DMSans';
  src: local('DMSans'), url(assets/fonts/DMSans/DMSans.ttf) format('truetype');
}

body {

  margin: 0;
  font-family: Poppins, DMSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none !important;
}