@use '../../../styles/mixins';
@import "../../../styles/variables";


.keyart, .keyart_layer {
  height: 1400px;

  @include mixins.mobile {
    height: 420px;
  }
}

#parallax {
  display: block;
}

.keyart {
  position: relative;
  z-index: 10;
}

.keyart_layer {
  background-position: bottom center;
  background-size: cover;
  width: 100%;
  position: absolute;
}
.keyart_layer.parallax {
  position: fixed;
}
#keyart-1 {
  background-image: url('../../../assets/parallax/Unchained7-min.png.webp') ;
  background-color: #121110;
}
#keyart-2 {
  background-image: url('../../../assets/parallax/Unchained6-min.png.webp') ;
}

#keyart-3 {
  background-image: url('../../../assets/parallax/Unchained5-min.png.webp') ;
}
#keyart-4 {
  background-image: url('../../../assets/parallax/Unchained4-min.png.webp') ;
}
#keyart-5 {
  background-image: url('../../../assets/parallax/Unchained3-min.png.webp') ;
}
#keyart-6 {
  background-image: url('../../../assets/parallax/Unchained2-min.png.webp') ;
}
#keyart-7 {
  background-image: url('../../../assets/parallax/Unchained1-min.png.webp') ;
}
#keyart-scrim {
  background-color: $mino_background-color;
  opacity: 0;
}