@import '../../../../styles/variables';


.headerContainer{
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding-bottom: 1rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.navSolid{
  background: $mino_background-color;
  -webkit-mask-image: -webkit-gradient(linear, left 80%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.navTransparent{

}

.actionButtonWrapper{
display: flex;
  gap: 1rem;
  margin-right: auto;
}

  /* CSS */
.appLaunchButton {
  padding: 0.6em 2em;
  border: none;
  box-shadow:
          0 0 1px 1px #fff,  /* inner */
          0 0 1px 1px #FFAE00, /* middle  */
          0 0 1px 1px #FF5500; /* outer */

  outline: none;
  color: rgb(255, 255, 255);
  background: $mino_background-color;
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-style: italic;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: Poppins,serif;

  &:hover{
  box-shadow:
          0 0 6px 3px #fff,  /* inner */
          0 0 10px 6px #FFAE00, /* middle  */
          0 0 14px 9px #FF5500; /* outer */
}
}






.linksContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.navInactive{
  display: table;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: $mino_main_font_size;
  font-weight: 900;
  white-space:nowrap;
  color: navajowhite;
  $brown: #584E4A;
  $whitey: #f0f0f0;
  $duration: .2s;
  $distance: 8px;
  $easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  position: relative;
  text-transform: uppercase;
  text-wrap: none;
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0; right: 0;
    height: 2px;
    background-color: coral;
  }
  &:before {
    opacity: 0;
    transform: translateY(- 8px); // distance
    transition: transform 0s $easeOutBack, opacity 0s;
  }
  &:after {
    opacity: 0;
    transform: translateY(8px); //distance
    transition: transform $duration $easeOutBack, opacity $duration;
  }
  &:hover,
  &:focus {
    &:before,
    &:after {
      opacity: 1;
      transform: translateY(0);
    }
    &:before {
      transition: transform $duration $easeOutBack, opacity $duration;
    }
    &:after {
      transition: transform 0s $duration $easeOutBack, opacity 0s $duration;
    }
  }

}
.linkText{
display: table-cell;
  vertical-align: middle;
}

.navActive{
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: $mino_main_font_size;
  white-space:nowrap;
  text-decoration: none;
  color: coral;
}


