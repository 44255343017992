.app{
  height: 100vh;
  overflow: hidden;
  font-family: Poppins,serif;

  &_hide {
    overflow-y: auto;
    height: auto;
  }
}

.fullScreen{
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100%;

  &_hide {
    display: none;
  }
}

a
{
  color: coral;
}