$mino_background-color: #121110;

$mino_headline_font_size: 5rem;
$mino_headline_font_size_mobile: 3rem;

$mino_headline_font_weight: 400;

$mino_main_font_size: 1rem;

$mino_main_font_weight: 100;

$mino_small_font_size: 0.8rem;
$mino_small_font_weight: 200;

$mino_sub_headline_font_size: 2rem;
$mino_sub_headline_font_weight: 200;

$mino_hover_effect_box_shadow:
0 0 6px 3px #fff,  /* inner */
0 0 10px 6px #FFAE00, /* middle  */
0 0 14px 9px #FF5500; /* outer */