@use '../../../styles/mixins';
@import "../../../styles/variables";
.logoText{
  opacity: 0;
  color: #fdfdfe;
  text-shadow: 0px 0px 5px orange, 0px 0px 10px orange, 0px 0px 10px orange,
  0px 0px 20px orange;
    font-family: Osake, serif;
    font-weight: 500;
    font-size: 3rem;
    @include mixins.mobile {
      font-size: 2rem;
    }
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.giveawayText{
  opacity: 0;
  color: #fdfdfe;
  text-shadow: 0px 0px 5px orange, 0px 0px 10px orange, 0px 0px 10px orange,
  0px 0px 20px orange;
  font-family: Osake, serif;
  font-weight: 300;
  font-size: 4rem;
  @include mixins.mobile {
    font-size: 4rem;
  }
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}