@use '../../../../styles/mixins';
@import '../../../../styles/variables';
.imageTextWrapper{
  width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  position: relative;
  text-align: center;
  background: transparent;
  @include mixins.mobile {
    flex-direction: column;
    width: 95%;
  }


}

.centerImage{
  display: flex;
  justify-content: center;
  align-items: center;
}


.wrapperContainerWithoutBg {
  margin-left: auto;
  margin-right: auto;
  z-index: 99;
  position: relative;
  text-align: center;
  background: $mino_background-color;
  -webkit-mask-image: -webkit-gradient(linear, left 90%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  padding-bottom: 2rem;

}