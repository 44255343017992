@import "../../../../styles/variables";

.accordionWrapper .accordionItem h3.accordionTitle {
  font-size: 1.75rem;
  margin: 0;

}

.accordionWrapper .accordionItem h3.accordionTitle button {
  position: relative;
  display: flex;
  align-items: center;
  background: $mino_background-color;
  border-radius: 1rem;
  border: none;
  font-size: 1.2rem;
  width: 100%;
  text-align: left;
  color: navajowhite;
  font-weight: bold;
  font-family: Poppins,sans-serif;
  margin-top: 0.5rem;
  padding: 1rem;
}

.accordionWrapper .accordionItem h3.accordionTitle button.active {
  background-color: coral;
  color: #ffffff;
  border: coral solid 1px;
}

.accordionWrapper .accordionItem h3.accordionTitle button:hover {
  background-color: coral;
  cursor: pointer;
}

.accordionWrapper .accordionItem h3.accordionTitle button span.titleWrapper {
  display: block;
  position: relative;
  width: 100%;
}

.accordionWrapper .accordionItem h3.accordionTitle button span.iconWrapper {
  width: 10%;
  display: flex;
  justify-content: center;
}


.accordionWrapper .accordionItem h3.accordionTitle button span.iconWrapper span.minus {
  content: url('../../../../assets/svg/arrowDown.svg');
  transform: rotate(-90deg);
  width: 24px;
  height: 24px;
}

.accordionWrapper .accordionItem h3.accordionTitle button span.iconWrapper span.plus {
  content: url('../../../../assets/svg/arrowDown.svg');
  width: 24px;
  height: 24px;
  transition-timing-function: ease-in;
  transition: all 1s;
}

.accordionWrapper .accordionItem .accordionPanel .panelClose {
  background: #e0e0e0;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0 1rem;
  opacity:0;
  width:100%;
  height:0;
  overflow: hidden;
  transition-timing-function: ease-out;
  transition: all 1s;
}

.accordionWrapper .accordionItem .accordionPanel .panelOpen {
  overflow: hidden;
  background: #e0e0e0;
  padding: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  opacity:1;
  width:100%;
  height: auto;
  transition-timing-function: ease-in;
  transition: all 1s;
}

.accordionWrapper .accordionItem .accordionPanel p {
  margin: 0;
}

.faqContainer{
  display: flex;
  justify-content: center;
  align-items: center;

}

.text{
  font-family: DMSans,sans-serif;
}
