@import '../styles/variables';


.headerIcons{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding-right: 3rem;
}
.headerIconWrapper{
  margin: 0.1rem;
}

.headerIcon{
  width: 48px;
  margin-left: 0.1rem;
  margin-right: 0.1rem;

}

.logoTextWrapper{
  margin-right: auto;
  padding-left: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .headerLogo{
    width: 72px;
  }
  .logoText{
    font-family: Osake, serif;
    font-size: $mino_main_font_size;
    padding-left: 1rem;
    color: navajowhite;
  }
}
